const theme = {
  colors: {
    text: "#FFF",
    background: "#1b2125",
    primary: "#2f4120",
    secondary: "#78a640",
    accent: "#f6c614",
    light: "#f6f6f6",
    muted: "#1b2125",
  },
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "Open Sans, sans-serif",
    monospace: "Melo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  text: {
    paragraph: {
      marginTop: "1em",
      marginBottom: "1em",
    },
    social: {
      alignSelf: "flex-end",
      padding: "1em",
      margin: "0em",
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      color: 'secondary',
    },
  },
  buttons: {
    primary: {
      margin: '2',
      backgroundColor: "primary",
      ":hover, :focus": {
        backgroundColor: "secondary",
      },
    },
  },
  links: {
    social: {
      color: "black",
      ":hover, :focus": {
        color: "muted",
      },
    },
    nav: {
      color: "primary",
      ":hover, :focus": {
        color: "secondary",
      },
    },
  },
  styles: {
    h1: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      marginTop: 0,
      marginBottom: 3,
    },
    a: {
      color: "secondary",
      ":hover, :focus": {
        color: "primary",
      },
    },
    root: {
      margin: "0px",
      padding: "0px",
    },
  },
};

export default theme;
